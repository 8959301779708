import React from 'react'

const AppGallery = () => {
  return (
    <>
        <div className='banner'>
            <div className='scrolling-banner-top'></div>
        </div>
        <div className='showcase-text'>
            Tools & apps &#8212; built just for you.
        </div>
        <div className='banner'>
            <div className='scrolling-banner-bottom'></div>
        </div>
    </>
  )
}

export default AppGallery