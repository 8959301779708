import React, { useState, useEffect } from "react";
import Airtable from "airtable";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import background from "../../Assets/Roadmap_Backdrop.webp";
import { HiSparkles } from "react-icons/hi2";
import { Badge } from "../Global/Badges/Badge";

const Roadmap = ({ iOSSafari }) => {
  const [name, setName] = useState("");
  const [ideaType, setIdeaType] = useState("new-app-resource");
  const [ideaDescription, setIdeaDescription] = useState("");

  const [loading, setLoading] = useState(false);
  const [submittedIdea, setSubmittedIdea] = useState(false);

  let todayBody;
  let comingSoonBody;

  const resizeHandler = () => {
    if (todayBody) {
      if (iOSSafari) {
        document.getElementById("primary-connecting-line").style.height =
          todayBody.clientHeight + 89 + "px";
        document.getElementById("future-connecting-line").style.top =
          todayBody.clientHeight + 103 + "px";
      } else {
        document.getElementById("primary-connecting-line").style.height =
          todayBody.clientHeight + 88 + "px";
        document.getElementById("future-connecting-line").style.top =
          todayBody.clientHeight + 102 + "px";
      }
    }

    if (comingSoonBody) {
      if (iOSSafari) {
        document.getElementById("future-connecting-line").style.height =
          comingSoonBody.clientHeight + 89 + "px";
      } else {
        document.getElementById("future-connecting-line").style.height =
          comingSoonBody.clientHeight + 88 + "px";
      }
    }
  };

  window.addEventListener("resize", resizeHandler);

  useEffect(() => {
    todayBody = document.getElementById("today-timeline");
    comingSoonBody = document.getElementById("coming-soon-timeline");
    window.addEventListener("sl-after-hide", (e) => {
      if (e.target.id === "share-idea-dialog") {
        setName("");
        setIdeaDescription("");
      }
    });
    resizeHandler();

    return () => {
      window.removeEventListener("resize", resizeHandler);
      window.removeEventListener("sl-after-hide", (e) => {});
    };
  }, [todayBody, comingSoonBody]);

  const navigate = useNavigate();

  let todayItems = [
    {
      title: "Shoebox Launch",
      badge: "new",
      description: "Release of Shoebox 1.0 to the public.",
    },
    {
      title: "Dynamic Portfolios",
      badge: "feature",
      description:
        "Feature update to allow Shoebox Pro users to add any stocks or indices to track their HSA growth with more precision.",
    },
  ];
  let comingSoonItems = [
    {
      title: "Balance Development",
      badge: "coming-soon",
      description:
        "Initial ideation, prototyping, and development of Balance — a simple portfolio rebalancing utility.",
    },
  ];
  let futureItems = [
    {
      title: "Annual Review",
      badge: "",
      description:
        "A well-designed, holistic year-end review of your individual or family financial progress.  Establish a benchmark to gauge positive future growth.",
    },
    {
      title: "Analog & Digital Budgeting Assets",
      badge: "",
      description:
        "Whether you prefer screen or print, these documents provide a framework to keep you on track with weekly, monthly, and annual budgeting targets.",
    },
    {
      title: "The Blocks ID",
      badge: "",
      description:
        "A single account to access all that The Blocks has to offer.  As we continue to add new apps & resources to support your growth, we want passwords and sign-ins to be the last thing you have to think about.",
    },
  ];

  const shareIdea = () => {
    setLoading(true);
    const base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_SHARE_IDEA_TOKEN,
    }).base(process.env.REACT_APP_AIRTABLE_THE_BLOCKS_BASE);
    base("The Blocks Feedback").create(
      [
        {
          fields: {
            name: name,
            email: "-",
            feedbackType:
              ideaType === "new-app-resource"
                ? "Share Idea - New App/Resource"
                : "Share Idea - Feature for Existing App/Resource",
            feedbackNotes: ideaDescription,
          },
        },
      ],
      function (err) {
        if (err) {
          console.error(err);
          alert(err);
          setLoading(false);
          return;
        }
        if (!err) {
          setName("");
          setIdeaType("new-app-resource");
          setIdeaDescription("");
          setLoading(false);
          setSubmittedIdea(true);
        }
        setTimeout(() => {
          document.getElementById("share-idea-dialog")?.hide();
        }, 5000);
        setTimeout(() => {
          setSubmittedIdea(false);
        }, 6000);
      }
    );
  };

  return (
    <div className="roadmap-wrapper">
      <img
        alt="neutral background hero image for roadmap"
        src={background}
        className="roadmap-fold-hero"
      />
      <section className="twelvefiftycontent">
        <section className="roadmap-fold-header">
          <h1>So, what's next?</h1>
          <p>
            We’re already hard at work thinking about what to build next and
            adding new features to our existing apps.{" "}
          </p>
          <p>Take a peek below at what is coming up.</p>
          <p>
            Have an idea of something that would be awesome? We’d love to hear
            it!
          </p>
          <div className="fold-navigation">
            <a href="#timeline">
              <button onClick={() => navigate("#timeline")}>Take A Peek</button>
            </a>
            <button
              onClick={() =>
                document.getElementById("share-idea-dialog")?.show()
              }
            >
              Share Your Idea
            </button>
          </div>
        </section>
        <section id="timeline" className="roadmap-timeline">
          <div className="line-wrapper">
            {/* Today */}
            <div className="timeline-pill">
              <div className="pill-dot"></div>
              <div className="pill-body">
                <h2>Today</h2>
                <span>In Progress</span>
              </div>
            </div>
            <div id="today-timeline" className="timeline-section-items">
              {todayItems.map((item, index) => {
                return (
                  <div className="section-item" key={index}>
                    <span className="item-header">
                      <h3>{item.title}</h3>{" "}
                      {item.badge !== "" ? <Badge badge={item.badge} /> : null}
                    </span>
                    <div>{item.description}</div>
                  </div>
                );
              })}
            </div>

            {/* Coming Up */}
            <div className="timeline-pill">
              <div className="pill-dot"></div>
              <div className="pill-body">
                <h2>Coming Up</h2>
                <span>In Planning</span>
              </div>
            </div>
            <div
              id="coming-soon-timeline"
              className="timeline-section-items soon"
            >
              {comingSoonItems.map((item, index) => {
                return (
                  <div className="section-item" key={index}>
                    <span className="item-header">
                      <h3>{item.title}</h3>{" "}
                      {item.badge !== "" ? <Badge badge={item.badge} /> : null}
                    </span>
                    <div>{item.description}</div>
                  </div>
                );
              })}
            </div>

            {/* Future */}
            <div className="timeline-pill future">
              <div className="pill-dot"></div>
              <div className="pill-body">
                <h2>Future</h2>
                <span>In Concept</span>
              </div>
            </div>
            <div className="timeline-section-items future">
              {futureItems.map((item, index) => {
                return (
                  <div className="section-item" key={index}>
                    <span className="item-header">
                      <h3>{item.title}</h3>{" "}
                      {item.badge !== "" ? <Badge badge={item.badge} /> : null}
                    </span>
                    <div>{item.description}</div>
                  </div>
                );
              })}
            </div>
            <div className="timeline-section-items idea-invitation">
              <div className="section-item">
                <span className="item-header">
                  <h3>Add Your Ideas Here</h3>
                </span>
                <div>
                  Have an idea? Something that would make a process easier? Let
                  us know!
                </div>
                <a
                  onClick={() =>
                    document.getElementById("share-idea-dialog")?.show()
                  }
                >
                  Share An Idea
                </a>
              </div>
            </div>
            <div className="line-container primary">
              <div
                className="connecting-line"
                id="primary-connecting-line"
              ></div>
            </div>
            <div className="line-container future">
              <div
                className="connecting-line"
                id="future-connecting-line"
              ></div>
            </div>
          </div>
        </section>
      </section>
      <sl-dialog id="share-idea-dialog" class="share-idea-popup">
        <div className="share-idea-header" slot="label">
          Share An Idea
        </div>
        {submittedIdea ? (
          <div className="success-container">
            <HiSparkles />
            <h4>Thank you! We appreciate your suggestions!</h4>
          </div>
        ) : (
          <div className="form-container">
            <label>Your Name [for proper credit]</label>
            <input value={name} onChange={(e) => setName(e.target.value)} />
            <label>Idea Type</label>
            <sl-select value={ideaType} filled>
              <sl-option
                value="new-app-resource"
                onClick={() => setIdeaType("new-app-resource")}
              >
                New App/Resource
              </sl-option>
              <sl-option
                value="feature-for-existing"
                onClick={() => setIdeaType("feature-for-existing")}
              >
                Feature for Existing App/Resource
              </sl-option>
            </sl-select>
            <label>
              Description of{" "}
              {ideaType === "new-app-resource"
                ? "New App/Resource"
                : "Feature for Existing App/Resource"}
            </label>
            <textarea
              value={ideaDescription}
              onChange={(e) => setIdeaDescription(e.target.value)}
            />
          </div>
        )}
        <div slot="footer">
          <button
            className="share-button"
            onClick={() => {
              shareIdea();
            }}
            disabled={
              name?.length < 2 || ideaDescription?.length < 2 || loading
                ? "disabled"
                : null
            }
          >
            {loading ? (
              <Oval
                height={24}
                width={24}
                color="#ffffff"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#cecece"
                strokeWidth={3}
                strokeWidthSecondary={3}
                wrapperClass="mobile-spinner"
              />
            ) : (
              "Share"
            )}
          </button>
        </div>
      </sl-dialog>
    </div>
  );
};

export default Roadmap;
