import React, { useEffect, useState } from "react";
import { HiArrowSmRight } from "react-icons/hi";
import { TbArrowBackUp } from "react-icons/tb";
import { useLocation, Link } from "react-router-dom";

const Topic = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tagTitle, setTagTitle] = useState("");
  const [topicPosts, setTopicPosts] = useState();
  const [noPostsFound, setNoPostsFound] = useState(false);

  const location = useLocation();
  const tag = location.pathname.replace("/resources/", "");
  const topicTitle = tag.replace("-", " ").split(" ");

  const formatTitle = (title) => {
    let slug = title.slice(11).split("-");
    for (let i = 0; i < slug.length; i++) {
      slug[i] = slug[i].charAt(0).toUpperCase() + slug[i].slice(1);
    }
    let topic = slug.join(" ");
    setTagTitle(topic);
  };

  const tagUrl = `https://public-api.wordpress.com/rest/v1.1/sites/blocksfinance.wordpress.com/posts/?category=the-blocks/posts/${tag}`;

  const fetchPosts = async () => {
    await fetch(tagUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data.found > 0) {
          setNoPostsFound(false);
          setTopicPosts(data.posts);
        } else {
          setNoPostsFound(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    formatTitle(location.pathname);
    fetchPosts();
  }, [location]);

  const decodeEntity = (inputStr) => {
    var textarea = document.createElement("textarea");
    textarea.innerHTML = inputStr;
    return textarea.value;
  };

  const formatDate = (postDate) => {
    const formattedDate = new Date(postDate).toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return formattedDate;
  };

  const NoPostsAvailable = () => (
    <>
      <span className="no-posts">No Posts Available</span>
    </>
  );

  const TopicPosts = ({ posts }) => (
    <>
      {posts.map((post) => (
        <div
          key={post.ID}
          className="post-tile fade-in-bottom"
          style={{ "--index": topicPosts.indexOf(post) }}
        >
          {!post.post_thumbnail ? (
            <div></div>
          ) : (
            <div className="image-container">
              <img
                alt="blog post featured photo"
                src={post.post_thumbnail?.URL}
                className="post-image"
              />
            </div>
          )}
          <div>
            <h3>{decodeEntity(post.title)}</h3>
            <h5>{formatDate(post.date)}</h5>
            {decodeEntity(post.excerpt.slice(3, 155)) + "..."}
            <div>
              <Link to={post.slug} state={{ post: post }} className="read-more">
                Read More <HiArrowSmRight />
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );

  const Loading = () => {
    <>
      <span>Loading...</span>
    </>;
  };

  return (
    <div>
      <Link to="/resources" className="back-to-resources">
        <TbArrowBackUp /> all resources
      </Link>
      <h1 className="page-title">{tagTitle}</h1>
      <section className="tag-content">
        {isLoading ? (
          <Loading />
        ) : noPostsFound ? (
          <NoPostsAvailable />
        ) : (
          <TopicPosts posts={topicPosts} />
        )}
      </section>
    </div>
  );
};

export { Topic };
