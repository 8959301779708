import React from "react";
import { AppCard } from "./AppCard";
import availableApps from "./AvailableApps.json";
import { Link } from "react-router-dom";
import { TbArrowBackUp } from "react-icons/tb";

const AppCategory = ({ title, category }) => {
  let categoryApps = availableApps.filter((app) => {
    return app.category === category;
  });

  return (
    <div id="blocks-calculators">
      <Link to="/resources" className="back-to-resources">
        <TbArrowBackUp /> all resources
      </Link>
      <h1 className="page-title">{title}</h1>
      {categoryApps.map((app, index) => {
        return (
          <AppCard
            key={index}
            appIcon={app.appIcon}
            title={app.title}
            badge={app.badge}
            description={app.description}
            actions={app.actions}
          />
        );
      })}
    </div>
  );
};

export default AppCategory;
