import React, { useEffect, useState } from "react";

const PrivacyPolicy = () => {
  const [policyBody, setPolicyBody] = useState();

  const fetchPolicy = async () => {
    let url = `https://public-api.wordpress.com/rest/v1.1/sites/blocksfinance.wordpress.com/posts/?category=the-blocks/tb-legal/tb-privacy-policy`;

    await fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setPolicyBody(data.posts[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (!policyBody) {
      fetchPolicy();
    }
  }, []);

  return (
    <div className="privacy-policy twelvefiftycontent">
      <h1 className="page-title">Privacy Policy</h1>

      <section className="policy-container">
        <img
          alt="icon for displayed privacy policy"
          src={policyBody?.featured_image}
        />
        <h2>{policyBody?.title}</h2>
        <div
          className="policy-body"
          dangerouslySetInnerHTML={{ __html: policyBody?.content }}
        ></div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
