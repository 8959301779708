import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HiUser, HiCalendar, HiTag } from "react-icons/hi";
import { TbSignature } from "react-icons/tb";
import { CgCalendar } from "react-icons/cg";
import PageNotFound from "../../Global/Support/PageNotFound";
import { Oval } from "react-loader-spinner";

const Post = ({ recentPosts }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [singlePost, setSinglePost] = useState();
  const [postTopics, setPostTopics] = useState();

  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    if (location.state) {
      setSinglePost(location.state.post);
      setIsLoading(false);
    }
    let match = [];
    if (!location.state && recentPosts) {
      let parts = location.pathname.split("/");
      let slug = parts[parts.length - 1];
      recentPosts.map((post) => {
        if (slug === post.slug) {
          setSinglePost(post);
          match.push(post);
          setIsLoading(false);
          return;
        }
      });
      if (!singlePost && !location.state && recentPosts && match.length < 1) {
        setNotFound(true);
        setSinglePost(null);
        setIsLoading(false);
      }
    }
  }, [recentPosts, location]);

  const formatDate = (postDate) => {
    const formattedDate = new Date(postDate).toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return formattedDate;
  };

  const decodeEntity = (inputStr) => {
    var textarea = document.createElement("textarea");
    textarea.innerHTML = inputStr;
    return textarea.value;
  };

  const PostTags = () => (
    <>
      {Object.entries(singlePost?.categories).map((tag, i) => {
        if (i + 1 === Object.keys(singlePost?.categories).length) {
          return tag[0];
        } else {
          return tag[0] + ", ";
        }
      })}
    </>
  );

  if (notFound && !isLoading) {
    return <PageNotFound />;
  } else {
    return (
      <>
        {isLoading ? (
          <div className="page-content post-loader">
            <Oval
              height={80}
              width={80}
              color="#407DFF"
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#cecece"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <section className="blog-post">
            <section className="post-header">
              <h1>{decodeEntity(singlePost?.title)}</h1>
              <div className="post-meta">
                <div>
                  <TbSignature className="signature" />
                  {singlePost?.author?.name}
                </div>
                <div id="post-date">
                  <CgCalendar />
                  {formatDate(singlePost?.date)}
                </div>
                <div>
                  <HiTag />
                  {recentPosts && (singlePost || location.state) ? (
                    <PostTags />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </section>
            {singlePost?.featured_image ? (
              <section className="post-featured-image">
                <img
                  alt="blog post featured image"
                  src={
                    singlePost?.attachments[singlePost?.post_thumbnail?.ID]
                      ?.thumbnails?.large
                  }
                />
              </section>
            ) : (
              <section></section>
            )}

            <section className="post-body">
              <div dangerouslySetInnerHTML={{ __html: singlePost?.content }} />
            </section>
          </section>
        )}
      </>
    );
  }
  if (recentPosts && singlePost !== null && (location.state || singlePost)) {
  }
  if (singlePost === null && !location.state) {
    return (
      <div className="page-content post-loader">
        <Oval
          height={80}
          width={80}
          color="#407DFF"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#cecece"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }
};

export { Post };
