import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import { AppCard } from "./Apps/AppCard";
import availableApps from "./Apps/AvailableApps.json";
import availableTopics from "./AvailableTopics.json";

const Resources = () => {
  useEffect(() => {
    updateWidth();
  }, []);

  const [isDesktop, setIsDesktop] = useState(true);
  const location = useLocation();

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 550);
  };

  window.addEventListener("resize", updateWidth);

  const [expandedSidebar, setExpandedSidebar] = useState(false);

  return (
    <div className="twelvefiftycontent" id="resources">
      <section className="resources-tagline">
        {isDesktop ? (
          <>
            <h1>Well-designed, easy to use.</h1>
            <h1>Many more apps to come.</h1>
          </>
        ) : (
          <>
            <h1 className="mobile tagline-split">
              Well-designed,<br></br>easy to use.
            </h1>
            <h1 className="mobile">
              Many more apps<br></br>to come.
            </h1>
          </>
        )}
      </section>
      <Sidebar
        expandedSidebar={expandedSidebar}
        setExpandedSidebar={setExpandedSidebar}
        availableTopics={availableTopics}
      />
      <section
        className={
          expandedSidebar
            ? "resource-content-container with-expanded-sidebar"
            : "resource-content-container"
        }
      >
        {location.pathname === "/resources" ? (
          <section className="featured-content">
            <h1 className="page-title">All Apps</h1>
            <section className="resource-app-grid">
              {availableApps.map((app, index) => {
                return (
                  <AppCard
                    key={index}
                    appIcon={app.appIcon}
                    title={app.title}
                    badge={app.badge}
                    description={app.description}
                    actions={app.actions}
                  />
                );
              })}
            </section>
            {/* <h1 className='page-title'>New Resources</h1>
                        <h1 className='page-title'>Recent Posts</h1> */}
          </section>
        ) : (
          <Outlet />
        )}
      </section>
    </div>
  );
};

export default Resources;
