import React, { useState, useEffect } from "react";

const TermsOfUse = () => {
  const [terms, setTerms] = useState();

  const fetchTerms = async () => {
    let url = `https://public-api.wordpress.com/rest/v1.1/sites/blocksfinance.wordpress.com/posts/?category=the-blocks/tb-legal/tb-terms-of-use`;

    await fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setTerms(data.posts[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (!terms) {
      fetchTerms();
    }
  }, []);

  return (
    <div id="terms-of-use" className="twelvefiftycontent">
      <h1 className="page-title">Terms of Use</h1>

      <section className="terms-container">
        <div
          className="policy-body"
          dangerouslySetInnerHTML={{ __html: terms?.content }}
        ></div>
      </section>
    </div>
  );
};

export default TermsOfUse;
