import React from "react";
import { Link } from "react-router-dom";
import BlocksDark from "../../../Assets/TheBlocksDark.svg";
import { HiMailOpen } from "react-icons/hi";
import { AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import { Badge } from "../Badges/Badge";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <section className="footer-links">
          <div className="link-section">
            <label>Products</label>
            <div className="line-with-badge">
              <Link to="/resources/hsa">Shoebox</Link>
              <Badge badge="new" />
            </div>
            <Link to="/resources/calculators">Compound</Link>
            <div className="line-with-badge">
              <Link to="/resources/calculators">Balance</Link>
              <Badge badge="coming-soon" />
            </div>
            <Link to="/resources">Posts & Resources</Link>
          </div>

          <div className="link-section">
            <label>Company</label>
            <Link to="/announcements">Announcements</Link>
            <Link to="/roadmap">Roadmap</Link>
            <Link to="/about">About</Link>
          </div>

          <div className="link-section">
            <label>Resources</label>
            <Link to="/terms-of-use">Terms of Use</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/feedback">Feedback</Link>
          </div>
        </section>
        <section className="footer-copyright">
          <img alt="The Blocks logo" src={BlocksDark} />
          <div>
            <span>The Blocks Lab LLC &copy; {new Date().getFullYear()}.</span>{" "}
            <span>All Rights Reserved.</span>
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
